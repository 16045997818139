import React, { useEffect ,useRef} from 'react';
import { Helmet } from 'react-helmet';
let tvScriptLoadingPromise;

const Trading = () => {
  const container = useRef();
  useEffect(
    () => {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
          "symbols": [
            [
              "BINANCE:TRXUSD.P|1M"
            ]
          ],
          "chartOnly": false,
          "width": "100%",
          "height": "100%",
          "locale": "en",
          "colorTheme": "dark",
          "autosize": true,
          "showVolume": false,
          "showMA": false,
          "hideDateRanges": false,
          "hideMarketStatus": false,
          "hideSymbolLogo": false,
          "scalePosition": "right",
          "scaleMode": "Normal",
          "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
          "fontSize": "10",
          "noTimeScale": false,
          "valuesTracking": "1",
          "changeMode": "price-and-percent",
          "chartType": "area",
          "maLineColor": "#2962FF",
          "maLineWidth": 1,
          "maLength": 9,
          "lineWidth": 2,
          "lineType": 0,
          "dateRanges": [
            "1d|1",
            "1m|30",
            "3m|60",
            "12m|1D",
            "60m|1W",
            "all|1M"
          ]
        }`;
      container.current.appendChild(script);
    },
    []
  );

  return (
    <div className='trading'>
      <Helmet>
        <title>TRON Trading Community | TRONDAO</title>
        {/* <meta name="description"
                      content="What is happening in the world of TRON"/>
                <meta property="og:description" content="What is happening in the world of TRON"/> */}
        <meta property='og:title' content='TRON Trading Community | TRONDAO' />
      </Helmet>
      
      <div className='trading-content'>
        <h2 className='head'>TRON Trading Community</h2>
        <div style={{ height:'300px' }}>
          <div className="tradingview-widget-container"  ref={container}>
            <div className="tradingview-widget-container__widget"></div>
          </div>
        </div>
        <div className="tradingview-widget-copyright" style={{ marginTop:'1rem' }}>
            <p>
              TRX futures quotes are displayed on a TradingView chart. TradingView is a global charting platform traders and investors visit to
              <a href="https://www.tradingview.com/markets/cryptocurrencies/prices-all/" target="_blank">
                <span className="blue-text"> watch cryptocurrency prices</span>
              </a>, build comprehensive charts, read latest news, and use multiple tools to navigate world crypto markets.
            </p>
        </div>
        <p>
          The TRON Trading Community is dedicated to building an atmosphere that
          prioritizes education and the exchange of information. This, in turn,
          empowers individuals to make well-informed decisions when engaging in
          TRON-related endeavors.
        </p>
        <p>
          Our community actively engages in in-depth discussions and conducts
          thorough analyses of various aspects of the TRON ecosystem. Members
          consistently explore the myriad of decentralized applications and
          projects that reside within the TRON network, thereby contributing
          significantly to the advancement and evolution of this blockchain
          technology.
        </p>
        <p>
          We invite you to become a part of the TRON Trading Community on
          Telegram by following{' '}
          <a href='https://t.me/tronnetworkEN/4388211'>this link</a>.
        </p>
        <p>
          Feel free to also join our{' '}
          <a href='https://www.reddit.com/r/Tronix/'>TRON Reddit Community</a>{' '}
          and <a href='https://forum.trondao.org/'>TRON DAO Forum</a> to discuss
          anything about TRON.
        </p>
        <h4>What is TRX?</h4>
        <p>
          TRX serves as the primary native utility token on the TRON blockchain.
          Its key functions encompass powering the blockchain, facilitating
          feeless transactions through TRON's distinctive energy/bandwidth
          system, and serving as the intermediary for all TRC Tokens and
          decentralized applications established within the TRON blockchain.
        </p>
        <p>
          Furthermore, it is noteworthy that TRX was officially granted legal
          recognition as an authorized digital currency and medium of exchange
          within the Commonwealth of Dominica, with this status taking effect on
          October 7th, 2022.{' '}
          <a href='https://dominica.gov.dm/images/documents/tron_DOC-20221009-WA0010.pdf'>
            View Ordinance
          </a>
        </p>
        <h4>Where Can I Trade Crypto?</h4>
        <p>
          You can access a comprehensive list of exchanges offering TRX trading
          options by clicking <a href='https://tron.network/trx?lng=en'>here</a>
          . It's important to recognize that cryptocurrency trading carries
          inherent risks stemming from price volatility, security
          vulnerabilities, and regulatory shifts. We strongly recommend
          conducting meticulous research and exercising prudence when
          contemplating cryptocurrency-related activities.
        </p>
      </div>
    </div>
  );
};

export default Trading;
